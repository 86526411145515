import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import PrimeVue from 'primevue/config';
import Lara from '@amit/themes/presets/aura';
import hePV from '../locale/he.json';

import type { App, Plugin } from 'vue';

/**
 * Vue plugin for integrating PrimeVue theme and DS.
 */
export const PrimeVuePlugin: Plugin = {
  /**
   * Install the PrimeVue plugin.
   * @param app - The Vue application instance.
   */
  install(app: App): void {
    app.directive('tooltip', Tooltip);
    app.use(ToastService);
    app.use(PrimeVue, { unstyled: true, pt: Lara, locale: { ...hePV } });
  },
};

export default PrimeVuePlugin;
