export default {
  root: {
    class: [
      'paginator',

      // Flex & Alignment
      'flex items-center justify-between flex-wrap gap-2',

      // Spacing
      'px-0 py-2',

      // Shape
      'border-0',

      // Color
      'bg-transparent',
      'text-secondary-800 text-xs',
    ],
  },

  prev: ({ attrs }) => ({
    class: [
      'relative inline-flex items-center justify-center',
      ' p-1 ml-[2px]',
      ' leading-none rounded-md',
      'text-secondary-800 bg-white',
      'border-1 border-secondary-300',
      'transition duration-200',
      'user-none overflow-hidden',
      {
        'cursor-pointer': !attrs.disabled,
        'opacity-60': attrs.disabled,
      },
    ],
  }),
  next: ({ attrs }) => ({
    class: [
      'relative inline-flex items-center justify-center',
      ' p-1 ml-[2px]',
      ' leading-none rounded-md',
      'text-secondary-800 bg-white',
      'border-1 border-secondary-300',
      'transition duration-200',
      'user-none overflow-hidden',
      {
        'cursor-pointer': !attrs.disabled,
        'opacity-60': attrs.disabled,
      },
    ],
  }),
  current: {
    class: ['text-xs text-secondary-800'],
  },
};
