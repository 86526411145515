import { pausableWatch } from '@vueuse/core';
import LoginPage from './components/LoginPage.vue';
import { START_LOCATION } from 'vue-router';

import type { SetupOptions } from '../types';
import type { RouteLocationNormalized, Router } from 'vue-router';

export const HOME_ROUTE: Partial<RouteLocationNormalized> = {
  path: '/',
};
export const LOGIN_ROUTE: Partial<RouteLocationNormalized> = {
  name: 'app-login',
  path: '/login',
};

export const installLoginRoute = (router: Router) => {
  router.addRoute({
    path: LOGIN_ROUTE.path!,
    name: LOGIN_ROUTE.name,
    component: LoginPage,
  });
};

/**
 * Sets up router guards for authentication.
 * @param params - The parameters containing router, context, and state.
 * @param params.router - The Vue Router instance.
 * @param params.context - The authentication context.
 * @param params.state - The authentication state.
 */
export const setupRouterGuards = ({
  router,
  context,
  state,
  meta,
}: SetupOptions): void => {
  const { signInWithCustomToken } = context;

  /**
   * Determines if the user can access the route.
   * Waits for the authentication state to resolve, or times out after 3 seconds.
   */
  const ensureAuthReady = async (): Promise<void> => {
    return new Promise(resolve => {
      const timer = setTimeout(() => {
        console.warn('[Router Guard] Fallback triggered after timeout.');
        resolve();
      }, 3000);

      const { stop } = pausableWatch(
        () => state.authenticating,
        () => {
          stop();
          clearTimeout(timer);
          resolve();
        },
      );
    });
  };

  // Global navigation guard
  router.beforeEach(
    async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
      const { token, sso_id, redirect = HOME_ROUTE.path } = to.query;

      if (sso_id) {
        /**
         * Store the provided SSO ID in the session metadata for later linking.
         *
         * Note:
         * - This ID is used in `getCustomToken` to associate the external SSO account with the user's profile.
         */
        meta.ssoMatchId = sso_id as string;
      }

      if (token) {
        /**
         * Handle magic-link or token-based login.
         * - Signs in the user using a provided authentication token (e.g., from an email link or SSO provider).
         * - Removes the token from the URL after use for security and clean URLs.
         */
        await signInWithCustomToken(token as string);
        await router.replace({ ...to, query: {} }); // Ensures the URL is clean and doesn't expose sensitive tokens.
      }

      if (from === START_LOCATION || state.authenticating) {
        /**
         * Ensures the app waits for authentication to complete before proceeding.
         * This is critical for routes accessed on initial app load or when the auth state is resolving.
         */
        await ensureAuthReady();
      }

      if (state.authenticated && to.name === LOGIN_ROUTE.name) {
        /**
         * Redirect authenticated users away from the login page.
         * Send authenticated users to the original destination or the home route.
         */
        return redirect as string;
      }

      if (!to.meta.requiresAuth || state.authenticated) {
        /**
         * Grant access to public routes or routes where the user is already authenticated.
         *
         * Business Cases:
         * - For public routes (`requiresAuth: false`), users should have unrestricted access.
         * - For authenticated routes, ensure the user is already authenticated.
         *
         * Example:
         * - Public pages like "About Us" or "Contact" should be accessible to everyone.
         * - Authenticated users can access their dashboards or other secure pages.
         */
        return true;
      }

      /**
       * Redirect unauthenticated users to the login page.
       * - Includes a `redirect` query to bring the user back to their original destination after logging in.
       */
      return {
        name: LOGIN_ROUTE.name,
        query: to.fullPath !== HOME_ROUTE.path ? { redirect: to.fullPath } : {}, // Add a `redirect` only if not already on the home route.
      };
    },
  );
};
