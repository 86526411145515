export default {
  root: () => ({
    class: [
      'relative w-full',
      '[&>input]:w-full',
      '[&>*:first-child]:absolute',
      '[&>*:first-child]:h-full',
      '[&>*:first-child]:flex',
      '[&>*:first-child]:start-2',
      '[&>*:first-child]:justify-center',
      '[&>*:first-child]:items-center',
      '[&>*:last-child]:absolute',
      '[&>*:last-child]:h-full',
      '[&>*:last-child]:end-1',
      '[&>*:last-child]:flex',
      '[&>*:last-child]:justify-center',
      '[&>*:last-child]:items-center',
      '[&>*:last-child]:top-0',
    ],
  }),
};
