<template>
  <form class="mt-6 flex flex-col gap-10 justify-between" @submit="onSubmit">
    <div class="flex flex-col gap-5">
      <InputField
        name="username"
        :label="t('fields.username')"
        @keydown.enter="onSubmit"/>
      <PasswordField
        name="password"
        :label="t('fields.password')"
        @keydown.enter="onSubmit"/>
      <FormItem :error-message="errors.server_errors" />
    </div>

    <div class="mt-6 flex flex-col">
      <BaseButton
        :label="action"
        :aria-label="action"
        :loading="loading || isValidating"
        @click="onSubmit"/>
      <LoginSSO text />
    </div>
  </form>
</template>

<script setup lang="ts">
import FormItem from '@amit/foundation/forms/FormItem.vue';
import InputField from '@amit/foundation/forms/InputField.vue';
import PasswordField from '@amit/foundation/forms/PasswordField.vue';
import BaseButton from 'primevue/button';
import LoginSSO from './LoginSSO.vue';

import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { object, string } from 'zod';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';

import { useAuthState, useAuthContext, useAuthMeta, Scope } from '../../';
import { handleServerErrors } from '../form';
import { useAuthCheck } from '../recaptcha';
import messages from '../locale';

defineProps<{
  action?: string;
}>();

const loading = ref(false);
const AuthContext = useAuthContext();
const { origin, scope } = useAuthState();
const { ssoMatchId } = useAuthMeta();
const { t } = useI18n(messages);

const loginSchema = object({
  username: string({ required_error: t('login.validation.username') })
    .trim()
    .min(1, { message: t('login.validation.username') }),
  password: string({ required_error: t('login.validation.password') })
    .trim()
    .min(1, { message: t('login.validation.password') }),
  server_errors: string().optional(),
});

const { handleSubmit, resetForm, setFieldError, errors, isValidating } =
  useForm({
    validationSchema: toTypedSchema(loginSchema),
  });

const executeRecaptcha = useAuthCheck();
const onSubmit = handleSubmit(async ({ username, password }) => {
  const token = await executeRecaptcha();
  if (!token) {
    setFieldError('server_errors', t('errors.recaptcha_web'));
    return;
  }
  loading.value = true;
  const { onError, onSuccess } = AuthContext.getCustomToken({
    username,
    password,
    origin: origin.value,
    token,
    /**
     * Link the user's account with the provided SSO ID.
     * Used for first time SSO Login
     *
     * Business Cases:
     * - If the user is logging in through a Single Sign-On (SSO) provider,
     *   the SSO ID (`ssoMatchId`) is used to associate their external account
     *   with their existing profile in the application.
     * - This ensures a seamless login experience by linking SSO credentials
     *   to the user's internal account.
     *
     * Example:
     * - A user logs in through their Ministry of Education account (SSO ID provided by the query).
     * - The application links this SSO account to their existing profile.
     * - Next time, the system can login the user directly with ?token route param
     *
     * Important:
     * - Ensure `meta.ssoMatchId` is validated and securely handled to prevent
     *   unauthorized account linking.
     */
    sso_id: ssoMatchId.value || undefined,
  });

  onError(e => {
    loading.value = false;
    if (!e.response) {
      setFieldError('server_errors', t('errors.recaptcha_web'));
      return;
    }
    // @ts-ignore
    handleServerErrors(e.response, setFieldError, t);
  });

  onSuccess(response => {
    if (!response.data) {
      setFieldError('server_errors', 'no_response');
    }
    if (
      response.data.scope.every(role => !scope.value.includes(role as Scope))
    ) {
      setFieldError('server_errors', t('errors.not_allowed'));
    }

    if (errors.value.server_errors) {
      loading.value = false;
      return;
    }

    resetForm();
    AuthContext.signInWithCustomToken(response.data.token);
  });
});
</script>
