import ObjectID from 'bson-objectid';
import { formatDistanceToNow } from 'date-fns';
import { he } from 'date-fns/locale';

export function generateObjectId(): string {
  const objectID: any = new ObjectID();
  return objectID.toString();
}

export function hasChanges(
  selectedUser: Object,
  initialUserData: Object,
): boolean {
  const selectedUserNorrmalized = normalizeObject(selectedUser);
  const initialUserDataNormalized = normalizeObject(initialUserData);
  return (
    JSON.stringify(selectedUserNorrmalized) !==
    JSON.stringify(initialUserDataNormalized)
  );
}

export function normalizeObject(obj: Object): Object {
  const normalized = { ...obj };
  Object.keys(normalized).forEach(key => {
    if (normalized[key] === '' || normalized[key] === null) {
      delete normalized[key];
    }
  });
  return normalized;
}

export const timeAgo = (date: Date) => {
  return formatDistanceToNow(new Date(date), { addSuffix: false, locale: he });
};

export const TEACHER_TRAINING = 'הכשרת מורים';

export const scrollParent = (parentElement: Element | null = null, event) => {
  const threshold = 150;
  const scrollSpeed = 15;

  const scrollableParent =
    parentElement ?? document.querySelector('section.present > .r-stack');

  if (!scrollableParent) return;

  const { clientY } = event;
  const rect = scrollableParent.getBoundingClientRect();

  let scrollAmount = 0;
  if (clientY < rect.top + threshold) {
    scrollAmount = -scrollSpeed;
  } else if (clientY > rect.bottom - threshold) {
    scrollAmount = scrollSpeed;
  }

  if (scrollAmount !== 0) {
    scrollableParent.scrollBy({ top: scrollAmount, behavior: 'smooth' });
  }
};
