export default {
  root: ({ props }) => ({
    class: [
      // Flexbox
      'flex flex-col',

      // Position
      'relative',
      {
        '!transition-none !transform-none !w-screen !h-screen !max-h-full !top-0 !left-0':
          props.position == 'full',
      },

      // Size
      {
        'h-[calc(100%-40px)] w-[56%] !top-[20px]':
          props.position == 'left' || props.position == 'right',
        'h-auto w-full': props.position == 'top' || props.position == 'bottom',
      },

      // Shape

      // 'p-5',

      'border-0',
      {
        'rounded-r-lg': props.position == 'left',
        'rounded-l-lg': props.position == 'right',
      },

      'shadow-sm',

      // Colors
      'bg-white',
      'text-secondary-800',

      // Transitions
      'transition-transform',
      'duration-300',

      // Misc
      'pointer-events-auto',
    ],
  }),
  header: {
    class: [
      // Flexbox and Alignment
      'flex items-center justify-between',
      'shrink-0',

      // Spacing
      'px-5 py-4',

      // Colors
      'text-secondary-800',

      // border
      'border-b border-secondary-250',
    ],
  },
  title: {
    class: ['text-secondary-800 font-simplerBold text-sm'],
  },
  icons: {
    class: ['flex items-center'],
  },
  pcCloseButton: {
    root: {
      class: [
        'relative',

        // Flexbox and Alignment
        'flex items-center justify-center',

        // Size and Spacing
        'w-5 h-5 p-1',

        // Shape
        'border-0',
        'rounded-full',

        // Colors
        'text-secondary-900',
        'bg-transparent',

        // Transitions
        'transition duration-200 ease-in-out',

        // States
        'hover:text-primary',
        // 'hover:bg-',
        'focus:outline-none focus:outline-offset-0 focus:ring-0',

        // Misc
        'overflow-hidden',
      ],
    },
  },
  closeButtonIcon: {
    class: [
      // Display
      'inline-block',

      // Size
      'w-3',
      'h-3',
    ],
  },
  content: {
    class: [
      // Spacing and Size
      'h-full',
      'w-full',

      // 'px-5 py-4', // should be added individually in your components

      // Growth and Overflow
      'grow',
      'overflow-y-auto',
    ],
  },
  mask: ({ props }) => ({
    class: [
      // Transitions
      'transition-all',
      'duration-300',
      { 'p-5': !props.position == 'full' },
      {
        '!justify-end': props.position == 'left',
        '!justify-start': props.position == 'right',
      },

      // Background and Effects
      // { 'has-[.mask-active]:bg-transparent bg-[#25283969]': props.modal },
    ],
  }),
  transition: ({ props }) => {
    return props.position === 'top'
      ? {
          enterFromClass:
            'translate-x-0 -translate-y-full translate-z-0 mask-active',
          leaveToClass:
            'translate-x-0 -translate-y-full translate-z-0 mask-active',
        }
      : props.position === 'bottom'
        ? {
            enterFromClass:
              'translate-x-0 translate-y-full translate-z-0 mask-active',
            leaveToClass:
              'translate-x-0 translate-y-full translate-z-0 mask-active',
          }
        : props.position === 'left'
          ? {
              enterFromClass:
                '-translate-x-full translate-y-0 translate-z-0 mask-active',
              leaveToClass:
                '-translate-x-full translate-y-0 translate-z-0 mask-active',
            }
          : props.position === 'right'
            ? {
                enterFromClass:
                  'translate-x-full translate-y-0 translate-z-0 mask-active',
                leaveToClass:
                  'translate-x-full translate-y-0 translate-z-0 mask-active',
              }
            : {
                enterFromClass: 'opacity-0 mask-active',
                enterActiveClass: 'transition-opacity duration-400 ease-in',
                leaveActiveClass: 'transition-opacity duration-400 ease-in',
                leaveToClass: 'opacity-0 mask-active',
              };
  },
};
