<template>
  <BaseButton
    v-if="ssoEnabled && !ssoMatchId"
    icon="custom"
    :text="text"
    :label="`${t('login.options.sso')} ${text ? '>>' : ''}`"
    :aria-label="t('login.options.sso')"
    @click="redirectSSO">
    <template #icon>
      <img
        src="https://web.mashov.info/students/images/logoOwl.png"
        aria-hidden="true"
        class="w-4.5"
        :alt="t('login.options.sso')"/>
    </template>
  </BaseButton>
</template>

<script setup lang="ts">
import BaseButton from 'primevue/button';

import { useI18n } from 'vue-i18n';
import { useAuthMeta } from '../../';
import messages from '../locale';

type InputProps = {
  text?: boolean;
};

defineProps<InputProps>();

/**
 * If `ssoMatchId` not empty, we just back from SSO,
 * no need to show the option again
 */
const { ssoEnabled, ssoLoginUrl, ssoMatchId } = useAuthMeta();
const redirectSSO = () => {
  if (ssoLoginUrl.value && ssoEnabled.value) {
    window.location.href = ssoLoginUrl.value;
  }
};

const { t } = useI18n(messages);
</script>
