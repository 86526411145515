import katex from 'katex';
import { CUSTOM_KEYBOARDS, replaceKatexSigns } from './utils';
import { computed, nextTick, ref, watch } from 'vue';
import { MathfieldElement } from 'mathlive';

export function useFormulaEditor(props, keyboardSettings) {
  let field;
  const latexData = ref(null);
  const editMode = ref(props.node.attrs.editMode);
  const mathFieldRef = ref(null);
  const hasError = ref(false);

  const keyboardList = computed(() => {
    let { keyboard = [] } = props.node.attrs;
    if (keyboard.length === 0) {
      keyboard = keyboardSettings?.keyboardList ?? [
        'FULL',
        'numeric',
        'symbols',
        'alphabetic',
      ];
    }

    return keyboard.map(key => CUSTOM_KEYBOARDS[key] ?? key);
  });

  const fixLines = string => {
    return string.replace(
      /\\displaylines\s*\{([\s\S]*?)\}/g,
      '\\begin{array}{l}$1\\end{array}',
    );
  };
  const computedKatex = computed(() => {
    let purifiedKatex = replaceKatexSigns(props.node.attrs.katex.toString());
    purifiedKatex = fixLines(purifiedKatex);

    try {
      var html = katex.renderToString(purifiedKatex, {
        throwOnError: true,
        safe: true,
        trust: true,
      });
      return html;
    } catch (e) {
      console.log('in error', e);
      hasError.value = true;
      return '<span class="text-red-500">התרחשה שגיאת ניתוח בנוסחה. לחץ על הנוסחה כדי לערוך ולתקן שגיאות בתחביר, כגון סוגריים חסרים או אופרטורים שגויים</span>';
    }
  });

  const isEmptyKatex = computed(() => {
    const htmlString = computedKatex.value;
    const container = document.createElement('div');
    container.innerHTML = htmlString;
    const katexHtml = container.querySelector('.katex-html');
    if (!katexHtml || katexHtml.innerHTML.trim() === '') {
      return true;
    }
    return false;
  });

  watch(editMode, newValue => {
    if (newValue) {
      loadMathLive(mathFieldRef);
    }
  });

  const toggleEdit = (e, val?: boolean) => {
    editMode.value = val ?? !editMode.value;
    props.updateAttributes({
      editMode: editMode.value,
    });
  };

  const toggleKeyboard = () => {
    if (window?.mathVirtualKeyboard.visible) {
      window?.mathVirtualKeyboard.hide();
    } else {
      window?.mathVirtualKeyboard.show();
      field?.focus({ preventScroll: true });
    }
  };

  const showKeyboard = () => {
    window?.mathVirtualKeyboard.show();
    field?.focus({ preventScroll: true });
  };

  const loadMathLive = mathFieldRef => {
    nextTick(() => {
      window.mathVirtualKeyboard.layouts = keyboardList.value;

      field = new MathfieldElement();

      const keyboardContainers =
        document.getElementsByClassName(`keyboard-container`);
      // mathVirtualKeyboard.container = keyboardContainer
      if (keyboardContainers.length > 0) {
        window?.mathVirtualKeyboard.addEventListener('geometrychange', () => {
          //@ts-ignore
          keyboardContainers.forEach(keyboardContainer => {
            if (window?.mathVirtualKeyboard.visible) {
              keyboardContainer.classList.remove('h-0');
              keyboardContainer.style.height = '250px';
            } else {
              keyboardContainer.style.height = '0px';
              setTimeout(() => {
                keyboardContainer.classList.add('h-0');
              }, 300);
            }
          });
        });
      }

      if (mathFieldRef?.value) {
        mathFieldRef.value.appendChild(field);
        field.setValue(props.node.attrs.katex);

        field.addEventListener('input', e => {
          if (e.inputType === 'insertLineBreak') {
            toggleEdit(false);
          }
          latexData.value = replaceKatexSigns(field.getValue());
          props.updateAttributes({
            katex: latexData.value,
          });
        });

        field.addEventListener('keypress', e => {
          e.preventDefault();
          e.stopPropagation();

          if (e.key === ' ') {
            field.executeCommand('insert', '\\enspace'); // Insert a space inside MathField
          }
          if (e.key === 'Enter') {
            if (e.shiftKey) {
              field.executeCommand('insert', '\\displaylines');
              return;
            }
            toggleEdit(false);
          } else {
            field.executeCommand('insert', e.key);
          }
        });
      }

      if (editMode.value) {
        setTimeout(() => {
          showKeyboard();
        }, 150);
      }
    });
  };

  return {
    editMode,
    mathFieldRef,
    keyboardList,
    computedKatex,
    field,
    isEmptyKatex,
    hasError,
    toggleEdit,
    loadMathLive,
    toggleKeyboard,
    showKeyboard,
  };
}
