export default {
  root: ({ props, context, parent }) => ({
    class: [
      // Flex
      'flex items-center justify-center',
      // Shape
      'first:rounded-s-md',
      'last:rounded-e-md',
      'border-y',
      'first:border-s',
      'last:border-e',

      // Space
      'p-2',

      // Size
      'min-w-[2.5rem]',

      // Color
      'bg-transparent',
      'text-primary',
      'border-secondary-300',
      { '!border-red-500': parent.attrs.class?.includes('p-invalid') },
    ],
  }),
};
