import createAlert from 'sweetalert';
import PrimeVue from 'primevue/config';
import Lara from '@amit/themes/presets/aura';

export const getRandomElementFromArray = (array: string[]) => {
  return array[Math.floor(Math.random() * array.length)];
};

export const defaultAvatar =
  'https://res.cloudinary.com/dcodkxpej/image/upload/f_auto,q_auto,c_fill,h_200,w_200/v1672660848/Amit/Avatars/avatar_0-10_geljvo.svg';

export const showAlert = (title, text, icon, buttons) => {
  return createAlert({
    title: title,
    text: text,
    icon: icon,
    buttons: buttons,
    dangerMode: true,
  });
};

import { createApp, h } from 'vue';
import { LeaveConfirmationModal } from '@amit/components';

export function showConfirmModal(
  header = 'Confirmation',
  message = 'Are you sure?',
  component = LeaveConfirmationModal,
) {
  return new Promise<boolean>(resolve => {
    const app = createApp({
      data() {
        return { open: true };
      },
      render() {
        return h(
          component,
          {
            open: this.open,
            onConfirm: (result: boolean) => {
              this.open = false;
              setTimeout(() => {
                app.unmount();
                resolve(result);
              }, 500);
            },
            onClose: () => {
              resolve(false);
              this.open = false;
              setTimeout(() => {
                app.unmount();
              }, 600);
            },
          },
          {
            header: () => h('div', header),
            default: () => h('div', message),
          },
        );
      },
    });

    app.use(PrimeVue, { unstyled: true, pt: Lara });
    const container = document.createElement('div');
    document.body.appendChild(container);
    app.mount(container);
  });
}
