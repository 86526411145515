export function useLocale(locale) {
  const messages = {
    en: {
      next: 'Next',
      back_to_exercise: '< Back to exercise',
      lesson: 'Lesson',
      send: 'Submit',
      submit: 'Submit',
      close: 'Close',
      word_bank: 'Word bank',
      my_answer: 'My answer',
      bonus: 'Bonus',
      reflection: 'Pause and think',
      correct: 'Try again',
      cancel: 'Cancel',
      attempts_left: 'Attempts left',
      finish: 'Finish lesson',
      changes_requested: 'Teacher requested changes, retry',
      reset: 'Reset',
      reset_title: 'איפוס תשובות',
      reset_question: 'האם אתה בטוח שברצונך לאפס את השאלה?',
      reset_message: 'איפוס ימחק את כל התשובות שענית ויחזור לנקודת ההתחלה.',
      reset_cancel: 'ביטול',
      reset_confirm: 'איפוס תשובות',
      full_screen: 'Full Screen',
      exit_full_screen: 'Exit Full Screen',
    },
    he: {
      next: 'הבא',
      back_to_exercise: '< חזרה לתרגיל',
      lesson: 'שיעור',
      send: 'שלח',
      submit: 'הגשה',
      close: 'סגור',
      finish: 'סיום השיעור',
      word_bank: 'בנק תשובות',
      my_answer: 'התשובה שלי',
      bonus: 'בונוס',
      reflection: 'עוצרים חושבים',
      correct: 'תיקון',
      cancel: 'ביטול תיקון',
      attempts_left: 'אפשרויות תיקון נשארו',
      changes_requested: 'המורה ביקש שינויים, נסה שוב',
      reset: 'איפוס',
      reset_title: 'איפוס תשובות',
      reset_question: 'האם אתה בטוח שברצונך לאפס את השאלה?',
      reset_message: 'איפוס ימחק את כל התשובות שענית ויחזור לנקודת ההתחלה.',
      reset_cancel: 'ביטול',
      reset_confirm: 'איפוס תשובות',
      full_screen: 'מסך מלא',
      exit_full_screen: 'יציאה ממסך מלא',
    },
  };

  const getLocalizedText = key => {
    const lc = locale.value || 'he';
    return messages[lc][key] || '';
  };

  return {
    getLocalizedText,
  };
}
