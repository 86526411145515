import defaultTheme from 'tailwindcss/defaultTheme';

export const fontFamily = {
  simplerLight: 'SimplerLight',
  simplerRegular: 'SimplerRegular',
  simplerBold: 'SimplerBold',
  simplerBlack: 'SimplerBlack',
  anomaliaBold: 'AnomaliaBold',
  anomaliaLight: 'AnomaliaLight',
  anomaliaRegular: 'AnomaliaRegular',
  roboto: 'Roboto',
};
export const animation = {
  blink: 'blink 1s ease-in-out infinite',
};
export const keyframes = {
  blink: {
    '0%': { opacity: '1' },
    '50%': { opacity: '0' },
    '100%': { transform: '1' },
  },
};

export const padding = { ...defaultTheme.spacing };
export const margin = {
  ...defaultTheme.spacing,
  3.5: '14px',
};

export const fontSize = {
  xxs: ['0.625rem', '0.875rem'], //10px
  xs: ['0.75rem', '1rem'], //12px
  sm: ['0.875rem', '1.125rem'], //14px
  base: ['1rem', '1.25rem'], //16px
  md: ['1.125rem', '1.375rem'], //18px
  lg: ['1.375rem', '1.625rem'], //22px
  xl: ['1.625rem', '1.875rem'], //26px
  '2xl': ['1.875rem', '2.125rem'], //30px
  '3xl': ['2.125rem', '2.375rem'], //34px
  '4xl': ['2.5rem', '2.75rem'], //40px
  '5xl': ['2.75rem', '3rem'], //44px
  '6xl': ['3rem', '3.25rem'], //48px
};
export const boxShadow = {
  ...defaultTheme.boxShadow,
  'sm-light': '0px 1px 7px 0px #CBCCE5',
  'box-light': '0px 1px 10px rgba(12, 74, 110, 0.05)',
  'dropdown-light':
    '0px 1px 6px 2px rgba(12, 74, 110, 0.05), 0px 2px 10px 0px rgba(12, 74, 110, 0.10)',
  'right-side': '21px 0px 14px -3px rgba(228,234,242,0.72)',
  DEFAULT: '0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.02)',
  xs: '0px 1px 3px 0px rgba(12, 74, 110, 0.04), 0px 1px 5px 0px rgba(12, 74, 110, 0.05)',
  sm: '0px 1px 2px 0px rgba(12, 74, 110, 0.05), 0px 1px 10px 0px rgba(12, 74, 110, 0.05)',
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.08), 0 2px 4px -1px rgba(0, 0, 0, 0.02)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(0, 0, 0, 0.01)',
  xl: '0 20px 25px -5px rgba(0, 0, 0, 0.08), 0 10px 10px -5px rgba(0, 0, 0, 0.01)',
  light:
    '0px 2px 10px 0px rgba(12, 74, 110, 0.10), 0px 1px 6px 2px rgba(12, 74, 110, 0.05)',
};
export const colors = {
  ...defaultTheme.colors,
  transparent: 'transparent',
  white: '#ffffff',
  black: '#000000',
  default: '#0F172A',
  blur: 'rgba(20.77, 56.05, 72.25, 0.2)',
  imgFound: 'rgba(0, 0, 0, 0.40)',

  primary: {
    50: '#F5FBFF',
    100: '#EBF8FF',
    200: '#DBF2FF',
    300: '#BBE7FF',
    400: '#99DAFF',
    450: '#70CCFF',
    500: '#52C1FF',
    600: '#0FA9FF',
    DEFAULT: 'var(--am-color-primary)', //0FA9FF
    650: '#0096EB',
    700: '#0083CC',
    800: '#006FAD',
    900: '#005585',
    950: '#004166',
    975: '#002133',
  },

  green: {
    50: '#F4FBF4',
    100: '#E2F3E3',
    200: '#C5E8C6',
    300: '#AFDFB1',
    400: '#92D395',
    450: '#6DC572',
    500: '#49B64E',
    600: '#3F9F44',
    700: '#378B3B',
    800: '#2C6D2F',
    900: '#235826',
    950: '#173A19',
  },

  red: {
    50: '#FDF7F7',
    100: '#F9E1E1',
    200: '#F1BBBB',
    300: '#ECA2A2',
    400: '#E58080',
    450: '#DC5656',
    500: '#D63434',
    600: '#B82626',
    700: '#A12121',
    800: '#7F1A1A',
    900: '#661515',
    950: '#440E0E',
  },

  orange: {
    50: '#FEF8F1',
    100: '#FCE9CF',
    200: '#FADCB2',
    300: '#F8CF96',
    400: '#F6BE6F',
    450: '#F3A83F',
    500: '#F0920F',
    600: '#D17F0D',
    700: '#B66F0C',
    800: '#905809',
    900: '#734607',
    950: '#4D2F05',
  },

  secondary: {
    50: '#FAFCFE',
    75: '#F7F9FD',
    100: '#F3F7FC',
    200: '#E9EEF7',
    250: '#E3E9F2',
    300: '#D7DFEA',
    400: '#D1D9E5',
    450: '#C5CFDD',
    500: '#B9C4D4',
    600: '#A8B4C7',
    700: '#8795AB',
    800: '#67768E',
    900: '#495465',
    950: '#2B313B',
    975: '#1E232A',
  },

  pink: {
    50: '#FEF6F9',
    100: '#FDEDF4',
    200: '#FDD3E6',
    300: '#F9C8DE',
    400: '#F6ACCE',
    450: '#F391BD',
    500: '#F075AC',
    600: '#ED5A9C',
    700: '#E93586',
    800: '#D3176C',
    900: '#A51254',
    950: '#770D3D',
  },

  'orange-mango': {
    50: '#FEF9EC',
    100: '#FDF5DD',
    200: '#FBE6B1',
    300: '#FAE09E',
    400: '#F8D477',
    450: '#F6CA5A',
    500: '#F4BE34',
    600: '#F3B416',
    700: '#DFA30C',
    800: '#C28E0A',
    900: '#A57909',
    950: '#886307',
  },
  'green-spring': {
    50: '#F5FCEE',
    100: '#ECF9DC',
    200: '#D6F2B5',
    300: '#CAEEA0',
    400: '#B6E87D',
    450: '#A3E25A',
    500: '#8FDC38',
    600: '#80D025',
    700: '#70B620',
    800: '#609C1C',
    900: '#508217',
    950: '#406812',
  },

  lime: {
    50: '#FAFCE3',
    100: '#F7FAD1',
    200: '#EBF391',
    300: '#E5F075',
    400: '#DFEC51',
    450: '#D6E723',
    500: '#CCDC18',
    600: '#BBCA16',
    700: '#A1AE13',
    800: '#808A0F',
    900: '#666E0C',
    950: '#4C5309',
  },

  'aqua-marine': {
    50: '#EEFCF1',
    100: '#E5FAEA',
    200: '#C2F4CF',
    300: '#B1F1C1',
    400: '#8EEBA5',
    450: '#87DEBA',
    500: '#49DF6E',
    600: '#2FDA5A',
    700: '#23C74C',
    800: '#13AE3A',
    900: '#178232',
    950: '#126828',
  },

  'cyan-gentle': {
    50: '#EEFCFC',
    100: '#E1FAFA',
    200: '#BAF3F3',
    300: '#A0EEEE',
    400: '#86EAEA',
    450: '#63E3E3',
    500: '#26D9D9',
    600: '#23C7C7',
    700: '#1FADAD',
    800: '#1C9C9C',
    900: '#178282',
    950: '#126868',
  },

  'blue-sea': {
    50: '#F5F9FE',
    75: '#EDF5FF',
    100: '#E7F0FE',
    200: '#CADCFC',
    300: '#BBD3FB',
    400: '#9EC0FA',
    450: '#77A6F8',
    500: '#5192F6',
    600: '#347AF4',
    700: '#0D61F2',
    800: '#2057B6',
    900: '#1A4793',
    950: '#0F2957',
  },

  purple: {
    50: '#F2EEFC',
    100: '#EBE5FA',
    200: '#DED4F7',
    300: '#D8CBF6',
    400: '#C1ADF0',
    450: '#AA8EEB',
    500: '#9774E7',
    600: '#7C52E0',
    700: '#622FDA',
    800: '#5122BF',
    900: '#421C9C',
    950: '#331579',
  },

  mixed: {
    black: '#0F172A',
    white: '#FFFFFF',
  },
};

export const textColors = {
  ...colors,
  // primary: colors.base.black,
  inverted: colors.white,
  // secondary: pallete.neutral[600],
  // third: pallete.neutral[400],
  // light: pallete.neutral[150],
  // placeholder: pallete.neutral[400],
  // disabled: pallete.neutral[250],
  // error: pallete.red[600],
  // success: pallete.green[600],
  // 'on-action': pallete.primary[500],
  // active: pallete.green[500],
  // cancelled: pallete.red[500],
  // end: pallete.lime[600],
  // hold: pallete.orange[500],
};

export const borderWidth = {
  ...defaultTheme.borderWidth,
  1: '1px',
  2: '2px',
  DEFAULT: '1px',
  3: '3px',
  4: '4px',
  5: '5px',
  12: '12px',
  14: '14px',
};

export const borderRadius = {
  ...defaultTheme.borderRadius,
  none: '0',
  1: '1px',
  2: '2px',
  3: '3px',
  4: '4px',
  60: '60px',
  70: '70px',
  sm: '0.125rem',
  DEFAULT: '3px',
  md: '0.375rem',
  lg: '0.5rem',
  full: '9999px',
};
export const width = {
  ...defaultTheme.width,
  2.5: '0.625rem',
  2.8: '0.7rem',
  3.5: '0.875rem',
  4: '1rem',
  4.5: '1.125rem',
  7: '1.75rem',
  7.5: '1.875rem',
  8.5: '2.125rem',
  10.5: '2.625rem',
  11: '2.75rem',
  16.25: '4.0625rem',
  16.5: '4.125rem',
  20: '5rem',
  22: '5.5rem',
  24: '6rem',
  25: '6.25rem',
  29: '7.25rem',
  30: '7.5rem',
  32: '8rem',
  34: '8.5rem',
  36: '9rem',
  38: '9.5rem',
  45: '11.25rem',
  50: '12.5rem',
  55: '13.75rem',
  65: '16.25rem',
  70: '17.5rem',
  74: '18.5rem',
  80: '18rem',
  85: '20rem',
  90: '22.5rem',
  92: '23rem',
  100: '25rem',
  105: '26rem',
  110: '27.5rem',
  120: '30rem',
  130: '32.5rem',
  140: '35.5rem',
  148: '37rem',
  155: '38.75rem',
  200: '50rem',
  211: '845px',
  306: '1224px',
  '10/100': '10%',
  '13/100': '13%',
  '20/100': '20%',
  '25/100': '25%',
  '30/100': '30%',
  '33/100': '33%',
  '40/100': '40%',
  '60/100': '60%',
  '66/100': '66%',
  '85/100': '85%',
  '90/100': '90%',
  '200/100': '200%',
};
export const minWidth = {
  ...defaultTheme.minWidth,
  2.5: '0.625rem',
  3.5: '0.875rem',
  4: '1rem',
  6: '1.5rem',
  7: '1.75rem',
  7.5: '1.875rem',
  8.5: '2.125rem',
  10.5: '2.625rem',
  11: '2.75rem',
  16.25: '4.0625rem',
  16.5: '4.125rem',
  20: '5rem',
  36: '9rem',
  38: '9.5rem',
  44: '11rem',
  45: '11.25rem',
  56: '14rem',
  60: '15rem',
  72: '18rem',
  80: '20rem',
};
export const maxWidth = {
  ...defaultTheme.maxWidth,
  23: '5.75rem',
  38: '9.5rem',
  65: '16.25rem',
  96: '24rem',
  100: '25rem',
  120: '30rem',
  200: '50rem',
  220: '55rem',
  240: '60rem',
  '60/100': '60%',
  '8xl': '88rem',
  '9xl': '96rem',
};
export const height = {
  ...defaultTheme.height,
  modal: 'calc(100% - 2rem)',
  2.6: '0.65rem',
  3.5: '0.875rem',
  4: '1rem',
  4.5: '1.125rem',
  6.5: '1.625rem',
  7.5: '1.875rem',
  8: '2rem',
  8.5: '2.125rem',
  10: '2.5rem',
  10.5: '2.625rem',
  11: '2.75rem',
  12.5: '3.125rem',
  16.5: '4.125rem',
  18: '4.5rem',
  20: '5rem',
  22: '5.5rem',
  25: '6.2rem',
  26: '6.5rem',
  30: '7.5rem',
  32.5: '8.125rem',
  37.5: '9.375rem',
  38: '9.5rem',
  42: '10.5rem',
  45: '11.25rem',
  48: '12rem',
  50: '12.5rem',
  52: '13rem',
  56: '14rem',
  70: '17.5rem',
  74: '18.5rem',
  80: '18rem',
  85: '21.25rem',
  90: '22.5rem',
  100: '25rem',
  105: '26rem',
  110: '27.5rem',
  112.5: '27.125rem',
  120: '30rem',
  '10/100': '10%',
  '30/100': '30%',
  '66/100': '66%',
  '70/100': '70%',
  '85/100': '85%',
  '90/100': '90%',
  '95/100': '95%',
  '97/100': '97%',
};
export const minHeight = {
  ...defaultTheme.minHeight,
  3.5: '0.875rem',
  4: '1rem',
  4.5: '1.125rem',
  5: '1.25rem',
  6.5: '1.625rem',
  7.5: '1.875rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
  11: '2.75rem',
  12: '3rem',
  15: '3.75rem',
  20: '4rem',
  30: '7.5rem',
  50: '12.5rem',
  60: '15rem',
  92: '23rem',
  100: '25rem',
  120: '30rem',
  130: '32.5rem',
};
export const maxHeight = {
  ...defaultTheme.maxHeight,
  23: '5.75rem',
  30: '7.5rem',
  38: '9.5rem',
  112: '28rem',
  120: '30rem',
  156: '39rem',
};
export const spacing = {
  ...defaultTheme.spacing,
  0.5: '2px',
  1.2: '5px',
  1.5: '6px',
  1.75: '7px',
  2: '8px',
  2.25: '9px',
  2.5: '10px',
  3.5: '14px',
  4: '16px',
  5.5: '22px',
  7: '28px',
  9: '36px',
  12: '48px',
  13: '52px',
  15: '60px',
  18: '72px',
  20: '80px',
  24: '96px',
  25: '100px',
  26: '104px',
  27: '108px',
  28: '112px',
  29: '116px',
  30: '120px',
  31: '124px',
  39: '156px',
  65.75: '263px',
  '1/100': '1%',
  '2/100': '2%',
  '3/100': '3%',
  '4/100': '4%',
  '6/100': '6%',
  '9/100': '9%',
  '12.5/100': '12.5%',
  '15/100': '15%',
  '18/100': '18%',
  '20/100': '20%',
  '21/100': '21%',
  '22/100': '22%',
  '23/100': '23%',
  '24.5/100': '24.5%',
  '25/100': '25%',
  '26/100': '26%',
  '27/100': '27%',
  '28/100': '28%',
  '30/100': '30%',
  '32/100': '32%',
  '33/100': '33%',
  '34/100': '34%',
  '35/100': '35%',
  '38/100': '38%',
  '39/100': '39%',
  '40/100': '40%',
  '41/100': '41%',
  '42/100': '42%',
  '43/100': '43%',
  '45/100': '45%',
  '46/100': '46%',
  '47/100': '47%',
  '50/100': '50%',
  '55/100': '55%',
  '57/100': '57%',
  '60/100': '60%',
  '65/100': '65%',
  '66/100': '66%',
  '66.4/100': '66.4%',
  '67/100': '67%',
  '68/100': '68%',
  '70/100': '70%',
  '73/100': '73%',
  '75/100': '75%',
  '78/100': '78%',
  '79/100': '79%',
  '80/100': '80%',
  '85/100': '85%',
  '87/100': '87%',
  '90/100': '90%',
};
export const lineHeight = {
  ...defaultTheme.lineHeight,
  xxs: '0.875rem',
  xs: '1rem',
  sm: '1.125rem',
  base: '1.25rem',
  md: '1.375rem',
  lg: '1.5rem',
  xl: '1.625rem',
  '2xl': '1.875rem',
  '3xl': '2.125rem',
  '4xl': '2.375rem',
  '5xl': '2.75rem',
  '6xl': '2.75rem',
  '7xl': '3.25rem',
};
export const screens = {
  ...defaultTheme.screens,
  xs: '640px',
  sm: '768px',
  md: '1024px',
  lg: '1281px',
  xl: '1440px',
  '2xl': '1512px',
  '3xl': '1920px',
  'max-h-sm': { raw: '(min-height: 551px)' },
  'max-h-md': { raw: '(min-height: 630px)' },
  'max-h-lg': { raw: '(min-height: 830px)' },
};

const generateGrid = size => {
  const gridColumn = {};
  const gridTemplateColumns = {};
  const gridRow = {};
  const gridTemplateRows = {};
  const gridRowStart = {};
  const gridRowEnd = {};
  const gridColumnStart = {};
  const gridColumnEnd = {};
  for (let i = 1; i <= size; i++) {
    gridRow[`span-${i}`] = `span ${i} / span ${i}`;
    gridColumn[`span-${i}`] = `span ${i} / span ${i}`;
    gridTemplateColumns[i] = `repeat(${i}, minmax(0, 1fr))`;
    gridTemplateRows[i] = `repeat(${i}, minmax(0, 1fr))`;
    gridRowStart[i] = `${i}`;
    gridRowEnd[i] = `${i}`;
    gridColumnStart[i] = `${i}`;
    gridColumnEnd[i] = `${i}`;
  }
  return {
    gridColumn,
    gridTemplateColumns,
    gridRow,
    gridTemplateRows,
    gridRowStart,
    gridRowEnd,
    gridColumnStart,
    gridColumnEnd,
  };
};

export const backgroundImage = {
  welcome:
    'url(\'https://res.cloudinary.com/dcodkxpej/image/upload/v1692857935/colorful-geometric-pattern-background-handmade-prints_1_et89lf.jpg\')',
  lobby:
    'url(\'https://res.cloudinary.com/dcodkxpej/image/upload/v1694587677/unsplash_E813FON0wDQ_n2yrfx.png\')',
  courseCard:
    'url(\'https://res.cloudinary.com/dcodkxpej/image/upload/v1694588593/Image_4_p7yg4t.png\')',
};

export const strokeWidth = {
  '3': '3px',
  '4': '4px',
};

export const container = {
  center: true,
  padding: {
    DEFAULT: '1rem',
    sm: '2rem',
    lg: '4rem',
    xl: '5rem',
    '2xl': '6rem',
    '3xl': '14rem',
  },
};
export default {
  ...defaultTheme,
  darkMode: 'class',
  outline: {
    blue: '2px solid rgba(0, 112, 244, 0.5)',
  },
  zIndex: {
    60: '60',
  },
  container: container,
  screens: screens,
  fontFamily: fontFamily,
  fontSize: fontSize,
  boxShadow: boxShadow,
  colors: colors,
  borderWidth: borderWidth,
  backgroundImage: backgroundImage,
  width: width,
  minWidth: minWidth,
  maxWidth: maxWidth,
  height: height,
  minHeight: minHeight,
  maxHeight: maxHeight,
  spacing: spacing,
  padding: padding,
  margin: margin,
  animation: animation,
  keyframes: keyframes,
  lineHeight: lineHeight,
  borderRadius: borderRadius,
  strokeWidth: strokeWidth,
  ...generateGrid(8),
  ...generateGrid(4),
};
