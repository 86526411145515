import { Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import KatexView from '../views/KatexView.vue';
export default Node.create({
  name: 'MathKatexField',
  group: 'inline',
  draggable: false,
  inline: true,
  atom: true,
  selectable: true,
  addAttributes() {
    return {
      katex: {
        default: '',
        // default: 'formulaEditPanel',
        parseHTML: element => element.innerHTML.split('$')[1],
      },
      inline: {
        default: true,
      },
      editMode: {
        default: false,
      },
      keyboard: {
        default: [],
      },
      textAlign: {
        default: 'inherit',
        parseHTML: element => element.style.textAlign || 'inherit',
        renderHTML: attributes => {
          if (!attributes.textAlign) {
            return {};
          }
          return {
            style: `text-align: ${attributes.textAlign};`,
          };
        },
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: (element: HTMLElement) => {
          return element.hasAttribute('data-katex') ? {} : false;
        },
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      { 'data-katex': true, editMode: false },
      `$${HTMLAttributes.katex}$`,
    ];
  },
  addNodeView() {
    return VueNodeViewRenderer(KatexView);
  },
});
