export default {
  root: ({ props }) => ({
    class: [
      // Sizing and Shape
      'min-w-[8.5rem] z-999',

      { 'rounded-md': props.popup },

      // Spacing
      'p-2',

      // Colors
      'bg-white',
      'text-secondary-900',

      { 'shadow-light': props.popup },
    ],
  }),
  menu: {
    class: [
      // Spacings and Shape
      'list-none',
      'm-0',
      'p-0',
      'outline-none',
    ],
  },
  menuitem: {
    class: 'relative',
  },
  itemcontent: ({ context }) => ({
    class: [
      'text-xs font-simplerRegular',

      // Color
      'text-secondary-800',

      // {
      //   'text-primary': !context.focused && !context.active,
      //   'text-primary bg-surface-200': context.focused && !context.active,
      //   'text-primary-highlight-inverse bg-primary-highlight':
      //     (context.focused && context.active) || context.active || (!context.focused && context.active),
      // },

      // Transitions
      'transition-shadow',
      'duration-200',

      // 'rounded-xxs',

      // States
      {
        'hover:bg-[#F4F7FB]': !context.active,
        'hover:bg-[#F4F7FB] active:bg-[#EAEEF6]': context.active,
      },

      // Disabled
      { 'opacity-60 pointer-events-none cursor-default': context.disabled },
    ],
  }),
  itemlink: () => ({
    class: [
      'relative',
      // Flexbox

      'flex',
      'items-center',

      // Spacing
      'p-2',

      // color
      'text-secondary-900',

      // Misc
      'no-underline',
      'overflow-hidden',
      'cursor-pointer',
      'select-none',
    ],
  }),
  icon: {
    class: [
      // Spacing
      'mr-2',

      // Color
      'text-secondary-900',
    ],
  },
  label: {
    class: ['leading-[normal]'],
  },
  submenuheader: {
    class: [
      // Font
      'font-bold',

      // Spacing
      'm-0',
      'p-2',

      // Shape
      'rounded-tl-none',
      'rounded-tr-none',

      // Colors
      'bg-surface-0',
      'text-secondary-800',
    ],
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass:
      'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
};
