import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';
import type { UseApiReturn } from '@amit/composables/types';
import type { GetTokenParams, TokenResponse, User } from '../types';

export const getCustomToken = ({
  username,
  password,
  token,
  origin,
  sso_id,
}: GetTokenParams): UseApiReturn<{ data: TokenResponse }> => {
  return useApi(
    http.post('/auth/token', {
      username,
      password,
      token,
      origin,
      sso_id,
    }),
    false,
  );
};

export const getUserInfo = (): UseApiReturn<{ data: User }> => {
  return useApi(http.get('/auth/me'), false);
};
