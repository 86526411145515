export default {
  root: ({ state }) => ({
    class: [
      // Shape
      'rounded-lg',
      'shadow-sm',
      'border-0',

      // Size
      'max-h-[90vh]',
      'w-[50vw]',
      'm-0',

      // Color
      'bg-white',
      '[&:last-child]:border-b',
      // 'border-surface-200 dark:border-surface-700',

      // Transitions
      'transform',
      'scale-100',
      'z-[1300]',

      // Maximized State
      {
        'transition-none': state.maximized,
        'transform-none': state.maximized,
        '!w-screen': state.maximized,
        '!h-screen': state.maximized,
        '!max-h-full': state.maximized,
        '!top-0': state.maximized,
        '!left-0': state.maximized,
      },
    ],
  }),
  header: ({ props }) => ({
    class: [
      // Flexbox and Alignment
      'flex items-center justify-between',
      'shrink-0',

      // Spacing
      'px-5 py-4',

      // Colors
      'text-secondary-800',

      // border
      'border-b border-secondary-250',
    ],
  }),
  title: {
    class: ['text-secondary-800 font-simplerBold text-sm'],
  },
  icons: {
    class: ['flex items-center'],
  },
  pcCloseButton: {
    root: {
      class: [
        'relative',

        // Flexbox and Alignment
        'flex items-center justify-center',

        // Size and Spacing
        'w-5 p-1',

        // Shape
        'border-0',
        'rounded-full',

        // Colors
        'text-secondary-900',
        'bg-transparent',

        // Transitions
        'transition duration-200 ease-in-out',
        'ring-0',
        // States
        'hover:text-primary',
        // 'hover:bg-',
        'focus:outline-none focus:outline-offset-0 focus:ring-0',
        'bg-red-500',

        // Misc
        'overflow-hidden',
      ],
    },
  },
  // title: {
  //   class: ['font-semibold text-xl leading-[normal]'],
  // },
  // icons: {
  //   class: ['flex items-center'],
  // },
  // closeButton: {
  //   class: [
  //     'relative',
  //
  //     // Flexbox and Alignment
  //     'flex items-center justify-center',
  //
  //     // Size and Spacing
  //     'mr-2',
  //     'last:mr-0',
  //     'w-5 h-5',
  //
  //     // Shape
  //     'border-0',
  //     'rounded-full',
  //
  //     // Colors
  //     'text-secondary-900',
  //     'bg-transparent',
  //
  //     // Transitions
  //     'transition duration-200 ease-in-out',
  //
  //     // States
  //     // 'hover:text-surface-700 dark:hover:text-white/80',
  //     'hover:bg-[#F4F7FB]',
  //     'outline-none',
  //     // 'focus:outline-none focus:outline-offset-0 focus:ring-1',
  //     // 'focus:ring-primary-500 dark:focus:ring-primary-400',
  //
  //     // Misc
  //     'overflow-hidden',
  //   ],
  // },
  maximizablebutton: {
    class: [
      'relative',

      // Flexbox and Alignment
      'flex items-center justify-center',

      // Size and Spacing
      'mr-2',
      'last:mr-0',
      'w-7 h-7',

      // Shape
      'border-0',
      'rounded-full',

      // Colors
      'text-surface-500',
      'bg-transparent',

      // Transitions
      'transition duration-200 ease-in-out',

      // States
      'hover:text-surface-700 dark:hover:text-white/80',
      'hover:bg-surface-100 dark:hover:bg-[rgba(255,255,255,0.03)]',
      'focus:outline-none focus:outline-offset-0 focus:ring-0',

      // Misc
      'overflow-hidden',
    ],
  },
  closeButtonIcon: {
    class: [
      // Display
      'inline-block',

      // Size
      'w-4',
      'h-4',
    ],
  },
  maximizableicon: {
    class: [
      // Display
      'inline-block',

      // Size
      'w-4',
      'h-4',
    ],
  },
  content: ({ state, instance }) => ({
    class: [
      // Spacing
      'p-5',
      'rounded-md',

      // Shape
      {
        grow: state.maximized,
        'rounded-b-lg': !instance.$slots.footer,
      },

      'border-t border-secondary-250',

      // Colors
      'text-secondary-975 text-sm',

      // Misc
      'overflow-y-auto',
    ],
  }),
  footer: {
    class: [
      // Flexbox and Alignment
      'flex items-center justify-end',
      'shrink-0',
      'text-right',
      'gap-2',

      // Spacing
      'px-5',
      'pb-4 pt-2',

      // Shape
      'border-t-0',
      'rounded-b-lg',

      // Colors
      'bg-white dark:bg-surface-900',
      'text-surface-700 dark:text-surface-0/80',
      'border border-t-0 border-b-0',
      'border-surface-200 dark:border-surface-700',
    ],
  },
  mask: ({ props }) => ({
    class: [
      // Transitions
      'transition-all',
      'duration-300',
      // '!z-[1300]',
      { 'p-5': !props.position == 'full' },

      // Background and Effects
      { 'has-[.mask-active]:bg-transparent bg-black/40': props.modal },
    ],
  }),
  transition: ({ props }) => {
    return props.position === 'top'
      ? {
          enterFromClass:
            'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active',
          enterActiveClass: 'transition-all duration-200 ease-out',
          leaveActiveClass: 'transition-all duration-200 ease-out',
          leaveToClass:
            'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active',
        }
      : props.position === 'bottom'
        ? {
            enterFromClass: 'opacity-0 scale-75 translate-y-full mask-active',
            enterActiveClass: 'transition-all duration-200 ease-out',
            leaveActiveClass: 'transition-all duration-200 ease-out',
            leaveToClass:
              'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0 mask-active',
          }
        : props.position === 'left' ||
            props.position === 'topleft' ||
            props.position === 'bottomleft'
          ? {
              enterFromClass:
                'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0 mask-active',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass:
                'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0 mask-active',
            }
          : props.position === 'right' ||
              props.position === 'topright' ||
              props.position === 'bottomright'
            ? {
                enterFromClass:
                  'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active',
                enterActiveClass: 'transition-all duration-200 ease-out',
                leaveActiveClass: 'transition-all duration-200 ease-out',
                leaveToClass:
                  'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active',
              }
            : {
                enterFromClass: 'opacity-0 scale-75 mask-active',
                enterActiveClass: 'transition-all duration-200 ease-out',
                leaveActiveClass: 'transition-all duration-200 ease-out',
                leaveToClass: 'opacity-0 scale-75 mask-active',
              };
  },
};
