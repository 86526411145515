import { useEditor } from '@tiptap/vue-3';
import { useRichTextEditorExtensions } from './useExtensions';

export function useRichTextEditor({
  editable,
  model,
  onUpdate,
  onSelectionUpdate = () => {},
  onPaste,
  keyboard = true,
  extensions = {},
}) {
  return useEditor({
    editable: editable,
    content: model,
    //@ts-ignore
    keyboard,
    onUpdate,
    onSelectionUpdate,
    editorProps: {
      handleDOMEvents: {
        drop: (view, e) => {
          e.preventDefault();
        },
        paste: (view, e) => {
          if (onPaste) {
            onPaste(e, view);
          }
        },
        keydown: (view, e) => {
          if (!keyboard) {
            e.preventDefault();
          }
        },
        click: (view, e) => {
          //@ts-ignore
          const link = e.target.closest('a');
          if (link) {
            e.preventDefault();
            e.stopPropagation();
            window.open(link.href, '_blank');
          }
        },
        focus: (view, e) => {
          const newFocusTarget = e.relatedTarget;

          //@ts-ignore
          if (newFocusTarget && newFocusTarget.tagName === 'MATH-FIELD') {
            e.preventDefault();
            e.stopPropagation();
            setTimeout(() => {
              //@ts-ignore
              newFocusTarget.focus({ preventScroll: true });
              document.dispatchEvent(new Event('selection-change'));
            }, 10);
            return false;
          }
        },
      },
    },
    extensions: useRichTextEditorExtensions(extensions),
  });
}
