import { getAuth, onAuthStateChanged } from 'firebase/auth';
import * as Sentry from '@sentry/vue';
import type { User, Auth } from 'firebase/auth';
import type { SetupOptions } from '../types';
import { LOGIN_ROUTE } from './router.ts';

/**
 * Sets up authentication-related event hooks and state management.
 * @param params - The parameters containing router, context, and state.
 * @param params.context - The authentication context.
 * @param params.state - The authentication state.
 */
export const setupAuthEvents = ({
  router,
  context,
  state,
  onLogin,
}: SetupOptions): void => {
  const auth: Auth = getAuth();

  const { getUserInfo } = context;

  /**
   * Handles Firebase authentication state changes.
   * @param authUser - The authenticated Firebase user or null if not authenticated.
   */
  const handleAuthStateChange = async (
    authUser: User | null,
  ): Promise<void> => {
    if (!authUser) {
      state.authenticating = false;
      state.authenticated = false;
      return;
    }

    try {
      // Fetch user information
      const userInfo = await getUserInfo();

      state.authenticated = true;
      state.authenticating = false;

      /**
       * Trigger the login event with the user's data.
       *
       * This notifies all listeners that a user has successfully logged in,
       * allowing other parts of the application to respond (e.g., updating state, analytics).
       */
      await onLogin.trigger(userInfo.data);

      /**
       * Sets the current user in Sentry for error tracking and monitoring.
       *
       * This function links the user information to Sentry's error reporting
       * system, allowing errors to be associated with the specific user.
       * Useful for debugging user-specific issues.
       */
      Sentry.setUser({
        id: userInfo.data.id,
        username: userInfo.data.fullName,
      });

      if (router.currentRoute.value.name === LOGIN_ROUTE.name) {
        /**
         * Force re-navigation to the current route.
         * - Refreshing guards and re-executing route logic should
         *   redirect the user away from login view.
         */
        await router.replace({
          // Stay on the current route
          ...router.currentRoute.value,
          // Re-trigger navigation logic
          force: true,
        });
      }
    } catch (error) {
      console.error('[AuthEvents] Error during user info fetch:', error);
      state.authenticated = false;
      state.authenticating = false;
    }
  };

  // Watch for Firebase authentication state changes
  onAuthStateChanged(auth, handleAuthStateChange);
};
