import axios from 'axios';
import { useFirebase, useAuth } from '@amit/auth';
import type { AxiosInstance } from 'axios';

const http: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE,
});

async function getToken(forceRefresh = false): Promise<string | undefined> {
  const auth = useFirebase();
  return auth.currentUser?.getIdToken(forceRefresh);
}

export const defineInterceptors = () => {
  http.interceptors.request.use(
    async config => {
      const token = await getToken((config as any)._retry || false); // Force-refresh if retry
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  http.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;
      if (error.response?.status === 401) {
        if (originalRequest._retry) {
          const { logout } = useAuth();
          await logout();
          return Promise.reject(error);
        }

        // Mark as retried and retry the request with a refreshed token
        originalRequest._retry = true;
        return http(originalRequest); // Retry request with refreshed token
      }

      return Promise.reject(error);
    },
  );
};
defineInterceptors();

export default http;
