<template>
  <NodeViewWrapper
    as="span"
    class="p-1 inline katex-string overflow-y-hidden overflow-x-auto"
    dir="ltr"
    :class="{
      editable: editMode && editor.options.editable,
      'selected-katex': selected,
      hidden: !editMode && isEmptyKatex && !hasError,
    }"
  >
    <div v-if="editMode" class="w-fit min-h-10 block">
      <span
        ref="target"
        class="flex w-fit gap-2 items-center justify-center min-w-30 min-h-10 text-lg py-1"
      >
        <CircleCheckBig
          :size="20"
          class="stroke-green-600 hover:stroke-green-800"
          :class="{
            'opacity-50 pointer-events-none': isEmptyKatex,
            'cursor-pointer': !isEmptyKatex,
          }"
          @click="handleSave"
        />

        <div class="mathfield-container bg-white">
          <div ref="mathFieldRef" class="editable field" />
          <div @click="toggleKeyboard">
            <Keyboard
              class="cursor-pointer stroke-secondary-600 hover:stroke-secondary-800"
            />
          </div>
        </div>
      </span>
    </div>
    <div
      v-else
      class="w-fit min-h-10 relative editor-bg"
      :class="{ 'empty-katex': isEmptyKatex }"
    >
      <span
        class="text-lg py-1 font-katex"
        :class="{ 'pointer-events-none': !editor.options.editable }"
        @dblclick="editMode = editor.options.editable"
        v-html="computedKatex"
      />
    </div>
  </NodeViewWrapper>
</template>
<script setup lang="ts">
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import { CircleCheckBig, Keyboard } from 'lucide-vue-next';
import 'katex/dist/katex.min.css';
import { inject, onBeforeUnmount, onMounted, ref } from 'vue';
import { useFormulaEditor } from '@amit/tiptap/src/composables/useFormulaEditor.ts';
import { onClickOutside } from '@vueuse/core';
const props = defineProps(nodeViewProps);
const target = ref(null);
const selected = ref(false);
const keyboardSettings = inject('keyboardSettings');
const {
  editMode,
  computedKatex,
  mathFieldRef,
  toggleKeyboard,
  toggleEdit,
  loadMathLive,
  isEmptyKatex,
  hasError,
} = useFormulaEditor(props, keyboardSettings);
onMounted(() => {
  loadMathLive(mathFieldRef);
});

onClickOutside(target, e => {
  console.log(e.target);
  if (
    Array.from((e.target as HTMLElement).classList).some(
      cls => cls.startsWith('ML') || cls === 'svg-glyph' || cls === 'action',
    ) ||
    e.target.id.startsWith('ML')
  ) {
    return;
  }
  toggleEdit(false);
});

const handleSelectionUpdate = () => {
  const { selection } = props.editor.state;
  const { from, to } = selection;

  // Check if the node position is within the current selection range
  const nodePos = props.getPos();
  if (nodePos >= from && nodePos < to) {
    selected.value = true;
  } else {
    selected.value = false;
  }
};

const handleSave = e => {
  console.log('handleSave', isEmptyKatex.value);
  if (!isEmptyKatex.value) {
    toggleEdit(e);
  }
};

onMounted(() => {
  props.editor.on('selectionUpdate', handleSelectionUpdate);
});

onBeforeUnmount(() => {
  props.editor.off('selectionUpdate', handleSelectionUpdate);
});
</script>
<style>
math-field:focus-within {
  @apply outline-none rounded-lg;
}
/* Hide the menu toggle */
math-field::part(menu-toggle) {
  display: none;
}
math-field::part(virtual-keyboard-toggle) {
  @apply m-0 hidden;
}
math-field::part(content) {
  @apply ps-2 min-w-30;
}
math-field::part(container) {
  @apply min-w-40 ring-0  rounded-lg outline-none;
}
math-field::part(placeholder) {
  @apply ps-2;
}
.mathfield-container {
  @apply w-fit min-h-10 px-2 flex items-center gap-2 rounded-lg shadow-[0px_0px_4px_0px_rgba(0,0,0,0.22)];
}

.field {
  @apply min-w-30;
}

.blocks-container .empty-katex {
  @apply !bg-red-50;
}

.blocks-container .editor-bg:not(:has(.empty-katex)) {
  @apply bg-secondary-50 rounded-md;
}
.blocks-container .ProseMirror-selectednode:not(:has(.empty-katex)) {
  @apply bg-primary-100;
}

.blocks-container .ProseMirror-selectednode .editor-bg {
  @apply bg-primary-100;
}

.selected-katex {
  @apply bg-[#B6D7FE];
}

.selected-katex .editor-bg {
  @apply !bg-[#B6D7FE];
}

*[style*='text-align: left']:has(.katex-string) {
  justify-content: left;
}

*[style*='text-align: right']:has(.katex-string) {
  justify-content: right;
}

*[style*='text-align: center']:has(.katex-string) {
  justify-content: center;
}

.katex * {
  font-family: unset;
}
</style>
